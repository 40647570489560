<template>
  <el-main>
    <el-row>
      <el-col
        :span="22"
        :offset="1"
        :md="{ span: 18, offset: 3 }"
        style="margin-bottom: 15px; margin-top: 50px"
      >
        <div class="sub-header left-text" style="padding-bottom: 20px;">All Licenses</div>

        <el-row :gutter="20" v-if="licenses.length > 0">
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
            v-for="license in licenses"
            :key="license"
          >
            <div class="all-listing-container">
              <router-link :to="`/products/license/${license.licenseId}`">
                <img :src="license.displayImage" />
                <div class="license-name">{{ license.name }}</div>
              </router-link>
            </div>
          </el-col>
        </el-row>

        <el-empty description="No licenses." v-if="licenses.length === 0" />
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const licenses = computed(() => store.state.layout.licenses);

    return {
      licenses,
    };
  },
};
</script>
